<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M804-487q-11 0-19.5-8T774-515q-14-103-87.5-176.5T510-779q-12-2-20-10.5t-8-20.5q0-13 
            9-22t22-7q127 14 217 104t104 217q2 13-7 22t-23 9Zm-172 0q-10 
            0-18.5-6.5T602-511q-10-35-35.5-60.5T506-607q-11-3-17.5-10.5T482-636q0-15 10-24t24-6q54 13 
            93 52t53 93q3 14-6 24t-24 10Zm163 367q-122 0-242.5-60T336-336q-96-96-156-216.5T120-795q0-19 
            13-32t32-13h140q14 0 24.5 9.5T343-805l27 126q2 14-.5 25.5T359-634L259-533q56 93 125.5 
            162T542-254l95-98q10-11 23-15.5t26-1.5l119 26q15 3 25 15t10 28v135q0 19-13 32t-32 13Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>