<template>
    <div class="points">
        <div class="card">
            <div class="title">Points</div>
            <div class="balance" v-if="show">P 1000</div>
            <div class="balance" v-else>P ****</div>

            <div class="toggle" @click="show = !show">
                <hide :size="'20px'" v-if="show"/>
                <show :size="'20px'" v-else/>
            </div>
            
            <div class="box">
                <div class="item" @click="soon()">
                    <fund :size="'20px'"/>
                    <div class="text">Buy</div>
                </div>
                <div class="item" @click="soon()">
                    <send :size="'20px'"/>
                    <div class="text">Send</div>
                </div>
                <div class="item" @click="soon()">
                    <convert :size="'20px'"/>
                    <div class="text">Convert</div>
                </div>
            </div>
        </div>

        <div class="t">Transactions</div>
    </div>
</template>

<script>
import hide from '../../../icons/hide.vue';
import show from '../../../icons/show.vue'

import fund from '../../../icons/fund.vue'
import send from '../../../icons/send.vue';
import convert from '../../../icons/convert.vue';

export default {
    
    components: {
        hide, show,
        fund, convert, send,
    },
    data() {
        return {
            show: true
        }
    }
}
</script>

<style scoped>

    .card {
        margin-top: 20px;
        background: linear-gradient(to bottom right, var(--main), rgb(253, 51, 51));
        

        padding: 20px;
        border-radius: 5px;
        color: white;
        position: relative;
    }
    .toggle {
        position: absolute;
        top: 20px;
        right: 20px;
        fill: white;
    }
    .box {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        margin-top: 30px;
        border-radius: 5px;
        overflow: hidden;
    }
    .title {
        font-weight: 500;
    }
    .balance {
        font-size: 25px;
        font-weight: 300;
        margin-top: 20px;
    }
    .item {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: white;
        fill: white;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 15px 0px;
        transition: .3s;
    }
    .item:active {
        background-color: rgba(0, 0, 0, 0.8);
    }



    .t {
        margin-top: 20px;
        font-weight: 800;
    }

</style>