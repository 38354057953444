<template>
    <div class="wallet">
        <div class="head">
            <div class="icon back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Wallet</div>
            <div class="icon settings">
                <settings :size="'20px'"/>
            </div>
        </div>
        <div class="main">
            <div class="menu">
                <div class="item" :class="[view == 'ngn' ? 'a' : '']" @click="view = 'ngn'">NGN</div>
                <div class="item" :class="[view == 'points' ? 'a' : '']" @click="view = 'points'">Points</div>
            </div>

            <ngn v-if="view == 'ngn'"/>
            <points v-if="view == 'points'"/>
        </div>
        <menulayout class="fixed"/>
    </div>
</template>

<script>
import back from '../../icons/back.vue'
import settings from '../../icons/settings.vue'

import ngn from './ngn/view.vue'
import points from './points/view.vue'


import menulayout from '../../components/menu.vue';

export default {
    components: {
        back, settings,
        ngn, points,
        menulayout
    },
    data() {
        return {
            view: 'ngn'
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
    .wallet {
        background-color: white;
    }
    .head {
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
        position: relative;
    }
    .icon {
        position: absolute;
        top: 0px;

        height: 50px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: var(--trans);
    }
    .back {
        left: 0px;
    }
    .settings {
        right: 0px;
    }

    
    .title {
        font-weight: 500;
        text-align: center;
    }

    .main {
        height: calc(100vh - 110px);
        overflow: scroll;
        padding: 20px;
        box-sizing: border-box;
    }
    .menu {
        display: flex;
        gap: 20px;
    }
    .item {
        padding: 7px 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        font-size: 12px;
        font-weight: 700;
        transition: .3s;
        color: gray;
    }
    .item:active {
        background-color: var(--main);
        color: white;
    }
    .a {
        background-color: var(--main);
        color: #fff;
    }
</style>