<template>
    <div class="ngn">
        <div class="card">
            <div class="title">NGN</div>
            <div class="balance" v-if="show">₦ 100.00</div>
            <div class="balance" v-else>₦ ****</div>

            <div class="toggle" @click="show = !show">
                <hide :size="'20px'" v-if="show"/>
                <show :size="'20px'" v-else/>
            </div>
            
            <div class="box">
                <div class="item" @click="topup()">
                    <fund :size="'20px'"/>
                    <div class="text">Top Up</div>
                </div>
                <div class="item" @click="soon()">
                    <request :size="'20px'"/>
                    <div class="text">Request</div>
                </div>
                <div class="item" @click="soon()">
                    <coupon :size="'20px'"/>
                    <div class="text">Coupons</div>
                </div>
            </div>
        </div>

        <div class="t">Services</div>
        <div class="services">
            <div class="service">
                <div class="icon">
                    <airtimeicon :size="'20px'"/>
                </div>
                <div class="label">Airtime</div>
            </div>
            <div class="service">
                <div class="icon">
                    <dataicon :size="'20px'"/>
                </div>
                <div class="label">Data</div>
            </div>
            <div class="service">
                <div class="icon">
                    <tvicon :size="'20px'"/>
                </div>
                <div class="label">Cable TV</div>
            </div>
            <div class="service">
                <div class="icon">
                    <electricityicon :size="'20px'"/>
                </div>
                <div class="label">Electricity</div>
            </div>
        </div>

        <div class="t">Transactions</div>

        <loader class="l" v-if="loading"/>

        <transaction v-for="tx in transactions" :key="tx.ref" :tx="tx"/>

        <div class="empty" v-if="!loading && transactions.length == 0">
            <tx :size="'30px'"/>
            <div class="empty_text">You have not made any transaction</div>
        </div>

    </div>
</template>

<script>
import hide from '../../../icons/hide.vue';
import show from '../../../icons/show.vue'

import fund from '../../../icons/fund.vue'
import coupon from '../../../icons/coupon.vue';
import request from '../../../icons/request.vue';


import airtimeicon from '../../../icons/airtime.vue'
import dataicon from '../../../icons/data.vue'
import tvicon from '../../../icons/tv.vue'
import electricityicon from '../../../icons/electricity.vue';

import loader from '../../../components/loader.vue';
import tx from '../../../icons/transactions.vue';

import transaction from '../../../components/wallet/ngn/transaction.vue';

export default {

    components: {
        hide, show,
        fund, coupon, request,
        airtimeicon, dataicon, tvicon, electricityicon,
        loader,
        tx,
        transaction
    },
    data() {
        return {
            show: true,

            loading: false,
            transactions: []
        }
    },
    computed: {
        new_transaction() {
            return this.$root.$data.new_transaction
        }
    },
    watch: {
        new_transaction(val) {
            this.transactions.unshift(val)
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.loading = true
            this.$http.post('/wallet/transactions')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.transactions = r.transactions
                }
                this.loading = false
            })
        },
        topup() {
            this.$router.push('/topup')
        },

    }
}
</script>

<style scoped>
    .card {
        margin-top: 20px;
        background: linear-gradient(to bottom right, var(--main), rgb(253, 51, 162));
        

        padding: 20px;
        border-radius: 5px;
        color: white;
        position: relative;
    }
    .toggle {
        position: absolute;
        top: 20px;
        right: 20px;
        fill: white;
    }
    .box {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        margin-top: 30px;
        border-radius: 5px;
        overflow: hidden;
    }
    .title {
        font-weight: 500;
    }
    .balance {
        font-size: 25px;
        font-weight: 300;
        margin-top: 20px;
    }
    .item {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: white;
        fill: white;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 15px 0px;
        transition: .3s;
    }
    .item:active {
        background-color: rgba(0, 0, 0, 0.8);
    }



    .t {
        margin-top: 20px;
        font-weight: 800;
    }
    .services {
        margin-top: 20px;
        display: grid;
        grid-template-columns: auto auto auto auto;
        justify-content: space-between;
        gap: auto;
    }
    .service {
        text-align: center;
        transition: .3s;
    }
    .service:active {
        transform: scale(0.8);
    }
    .icon {
        width: 50px;
        height: 50px;
        fill: var(--main);
        background-color: var(--trans);
        border-bottom: 1px solid tomato;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }
    .label {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }

    .l {
        margin: 30px 0px;
    }


    .empty {
        text-align: center;
        margin: 30px 0px;
        fill: gray;
        font-size: 12px;
        color: gray;
    }
</style>