<template>
    <div class="tx">
        <div class="icon" v-if="tx.category == 'topup'">
            <fund :size="'20px'" :style="{'height': '20px'}"/>
        </div>
        <div class="mid">
            <div class="title">
                {{ tx.category == 'topup' ? 'Top up' : '' }}
                
                <vue-numeric read-only currency="₦" separator="," v-model="tx.amount">
                </vue-numeric>
            </div>
            <div class="date">{{date}}</div>
        </div>
        <div class="right">
            <marked :size="'20px'" class="green" v-if="tx.status == 'success'"/>
            <timeicon :size="'20px'" class="gray" v-if="tx.status == 'pending'"/>
        </div>
    </div>
</template>

<script>
import moment from 'moment'


import fund from '../../../icons/fund.vue'

import marked from '../../../icons/marked.vue'
import timeicon from '../../../icons/time.vue'


export default {
    components: { 
        fund,
        marked, 
        timeicon
    },
    props: ['tx'],
    data() {
        return {
            date: ''
        }
    },
    mounted() {
        this.date = moment(this.tx.created).format('Do MMM YYYY - HH:mm')
    }
}
</script>

<style scoped>
    .tx {
        border-radius: 5px;
        padding: 15px;
        margin-top: 20px;
        border: 1px solid #ddd;
        /* background-color: #edeef0; */
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--trans);
        fill: var(--main);

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title {
        font-size: 14px;
        font-weight: 600;
    }
    .date {
        font-size: 12px;
        margin-top: 5px;
    }


    .right {
        margin-left: auto;
    }
    .green {
        fill: green;
    }
    .gray {
        fill: #555;
    }
</style>