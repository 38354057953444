<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M668-478q-9-9-9-21t9-21l98-98H147q-13 
            0-21.5-8.5T117-648q0-13 8.5-21.5T147-678h619L666-778q-8-8-8-20t9-21q9-9 21-9t21 9l150 150q5 
            5 7 10t2 11q0 6-2 11t-7 10L709-477q-8 8-20 8t-21-9ZM250-141 101-290q-5-5-7-10t-2-11q0-6 
            2-11t7-10l151-151q8-8 20-8t21 9q9 9 9 21t-9 21l-99 99h619q13 0 21.5 8.5T843-311q0 13-8.5 
            21.5T813-281H194l99 99q8 8 8 20t-9 21q-9 9-21 9t-21-9Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>