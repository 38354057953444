<template>
    <div class="menu_layout">
        <div class="item" :class="c == 'home' ? 'a' : ''" @click="go_to('/home')">
            <home :size="'20px'"/>
            <div class="text">Home</div>
        </div>

        <div class="item" :class="c == 'message' ? 'a' : ''" @click="go_to('/message')">
            <chat :size="'20px'"/>
            <div class="text">Chats</div>
            <div class="dot" v-if="this.$root.$data.message_notification"></div>
        </div>

        <!-- <div class="item" :class="c == 'market' ? 'a' : ''" @click="go_to('/market')">
            <market :size="'20px'"/>
            <div class="text">Market</div>
        </div> -->

        <div class="item" :class="c == 'wallet' ? 'a' : ''" @click="go_to('/wallet')">
            <wallet :size="'20px'"/>
            <div class="text">Wallet</div>
        </div>
        
        <div class="item" :class="c == 'people' ? 'a' : ''" @click="go_to('/people')">
            <users :size="'20px'"/>
            <div class="text">People</div>
        </div>


        
        <div class="item" @click="open_profile()">
            <div class="dp">
                <img :src="$root.$data.dp"/>
            </div>
            <div class="text">Profile</div>
        </div>
    </div>
</template>

<script>
import home from '../icons/home.vue'
import chat from '../icons/chat.vue';
import wallet from '../icons/wallet.vue';
// import market from '../icons/market.vue'
import users from '../icons/users.vue'
// import user from '../icons/user.vue';

export default {
    components: {
        home, chat, wallet, users, 
        // user
    },
    computed: {
        c() {
            return this.$route.name
        }
    },
    methods: {
        go_to(link) {
            this.$router.push(link)
        },
        open_profile() {
            let profile = {
                'created': this.$root.$data.created,
                'verified': this.$root.$data.verified,
                'username': this.$root.$data.username,
                'firstname': this.$root.$data.firstname,
                'lastname': this.$root.$data.lastname,
                'location': this.$root.$data.location,
                'dp': this.$root.$data.dp,
                'cover': this.$root.$data.cover,
                'posts': this.$root.$data.posts,
                'comments': this.$root.$data.comments,
                'followers': this.$root.$data.followers,
                'followings': this.$root.$data.followings
            }

            localStorage.setItem('profile', JSON.stringify(profile))

            this.$router.push('/profile/' + this.$root.$data.username)
        }
    }
}
</script>

<style scoped>
    .menu_layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        background-color: white;
        position: relative;
        z-index: 2;
        padding: 0px 20px;
        box-sizing: border-box;
    }
    .item {
        text-align: center;
        fill: #555;
        padding: 10px 0px;
        position: relative;
    }
    .dot {
        background-color: tomato;
        width: 10px;
        height: 10px;
        border-radius: 50%;

        position: absolute;
        top: 5px;
        right: 0px;
    }
    .a {
        fill: var(--main);
        color: var(--main);
    }

    .dp {
        /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
        border: 1px solid #ddd;
        margin: 0 auto;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .dp img {
        width: 23px;
    }

    .text {
        font-size: 10px;
        font-weight: 500;
        margin-top: 2px;
    }
</style>