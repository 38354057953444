<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M571-328h60v-124h123v-60H631v-123h-60v123H447v60h124v124ZM233-144Q128-186 
            64-277.258q-64-91.258-64-203.5T64.5-684Q129-775 233-817v68q-79 36-126 108.7Q60-567.599 60-481q0 
            88 47 160t126 109v68Zm367 23q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T240-481q0-75 
            28.5-140.5t77-114q48.5-48.5 114-77T600-841q75 0 140.5 28.5t114 77q48.5 48.5 77 114T960-481q0 
            75-28.5 140.5t-77 114q-48.5 48.5-114 77T600-121Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>