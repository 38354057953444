<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="m864-617-50-23 50-23 23-50 23 50 50 23-50 23-23 50-23-50ZM725-816l-74-35 74-35 35-74 
            35 74 74 35-74 35-35 74-35-74ZM360-80q-34 0-57.5-23.5T279-161h162q0 34-23.5 
            57.5T360-80ZM228-223q-13 0-21.5-8.5T198-253q0-13 8.5-21.5T228-283h264q13 0 21.5 8.5T522-253q0 
            13-8.5 21.5T492-223H228Zm-25-121q-66-43-104.5-107.5T60-597q0-122 89-211t211-89q122 0 211 
            89t89 211q0 81-38 145.5T517-344H203Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>