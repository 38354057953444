<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M335-120v-60h86v-100H141q-24 0-42-18t-18-42v-440q0-24 18-42t42-18h680q24 0 42 
            18t18 42v440q0 24-18 42t-42 18H541v100h86v60H335Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>